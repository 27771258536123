import React from 'react';
import { Link } from 'gatsby'

import styles from './cta.module.scss';

export default (props) => (
  <div className={styles.ctaSection}>
    <div className={styles.description}>{props.text}</div>
    <Link to='/contact/main' className={styles.button}>{props.cta}</Link>
  </div>
)
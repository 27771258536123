import React from 'react';
import { Link } from 'gatsby'

import styles from './www.module.scss';

import {teaching_team} from '../images/team';
import {drawing} from '../images/stock';

export default (props) => (
  <div className={styles.workSection}>
    <div className={styles.header}>The Way We Work</div>
    <div className={styles.contentWrapper}>
      <div className={styles.row}>
        <div className={styles.col1}>
          <img src={drawing}/>
        </div>
        <div className={styles.col2}>
          <div className={styles.contentItem}>
            <div className={styles.meta}>01</div>
            <div className={styles.meta}>Mentorship</div>
            <div className={styles.description}>Your instructor is here not only to help you master the test but also to help you realize your potential in high school, university, and beyond.</div>
          </div>
          <div className={styles.contentItem}>
            <div className={styles.meta}>02</div>
            <div className={styles.meta}>Support</div>
            <div className={styles.description}>We put a whole team of support behind you; the entire blueprint team is always accessible to you, including teachers, counselors, program managers, and our broader global network.</div>
          </div>
        </div>
        <div className={styles.col3}>
          <div className={styles.contentItem}>
            <div className={styles.meta}>03</div>
            <div className={styles.meta}>Product Innovation</div>
            <div className={styles.description}>The world changes quickly, but we pride ourselves on keeping our students one step ahead with innovative product development and curriculum design.</div>
          </div>
          <div className={styles.contentItem}>
            <Link to='/team/main'><div className={styles.button}>Meet Our Team</div></Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)
import React from 'react';
import { Link } from 'gatsby'

import styles from './products.module.scss';

import Layout from '../../components/layout';
import CallToAction from '../../components/cta';
import WayWeWork from '../../components/www';

import { notebook, book_2, presentation } from '../../images/icons';
import { teaching_team } from '../../images/team';


export default () => (
  <Layout>
    <div className={styles.productPage}>
      <div className={styles.topSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>English Language Arts</div>
          <div className={styles.description}>Our English Language Arts programs utilize a curriculum of engaging and relevant literature to provide students with the foundational knowledge and analytical skills that will allow them to excel in their high school coursework and on standardized tests while preparing them for the rigors of university-level classes</div>
        </div>
      </div>
      <div className={styles.iconSection}>
        <div className={styles.card}>
          <img src={notebook} />
          <div className={styles.meta}>Challenging Curriculum</div>
          {/*<div className={styles.description}>Every student that joins our SAT program receives a customized plan for their preparation that is based on data. Using this cutting-edge analytical approach, we can learn the precise concepts each student struggles with on the exam. This allows us to provide customized and dynamic guidance to each student about how they can better improve upon their weaknesses.</div>*/}
          <div className={styles.description}>Our English Language Arts programs offer students an opportunity to enrich their English language skills through readings in award-winning and classic literature, essay composition, and the foundations of test preparation. Students will gain an appreciation of the value of reading, an expanded vocabulary, and strong writing skills.</div>
        </div>
        <div className={styles.card}>
          <img src={book_2} />
          <div className={styles.meta}>Great Preparation for Testing</div>
          {/*<div className={styles.description}>Students who enroll in at least 60 of hours of our SAT preparation program can expect to gain up to 200 points on their score.  This improvement is based on our curriculum, which emphasizes familiarity with the exam; just like any sport, mastering the SAT is all about PRACTICE--so in each of our lesson formats, we administer regular mock exams and tailored review sessions.</div>*/}
          <div className={styles.description}>Students who have taken our ELA program will have a head start on SAT or ACT test preparation. Our experience shows that students who have completed a year of ELA study with us score ahead of their peers on their first mock exams, no matter which test they choose.</div>
        </div>
        <div className={styles.card}>
          <img src={presentation} />
          <div className={styles.meta}>Dedicated & Experienced Instructors</div>
          {/*<div className={styles.description}>In addition to degrees from world class universities, all our instructors scored in the 99th percentile on both the SAT & ACT and are subject-matter experts in the specific subjects they each. Most importantly, they are teachers at heart and are heavily invested in the improvement of each individual student. Learn more about our amazing team here.</div>*/}
          <div className={styles.description}>Every instructor on our team is dedicated to instilling a passion for reading and learning. Our instructors, graduates from world-class universities, promote and environment that is fun and engaging while also a place for serious study and self-development. <Link to='/team/main'>Meet our amazing team here.</Link></div>
        </div>
      </div>
      <WayWeWork />
      <CallToAction text='Interested in signing up for this course?' cta='Register Today'/>
      <div className={styles.formatSection}>
        <div className={styles.header}>Lesson Formats</div>
        <div className={styles.description}>We offer all of our classes in a variety of formats. Please get in touch with us to discuss which options best suit your schedule.</div>
          <div className={styles.row}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>Weekly Program</div>
                <div className={styles.meta}>4 Months, Weekly Meetings</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Offered both fall and spring terms</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Engaging and enriching curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Weekday and weekend classes</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Classes available for High School, Middle School, and Elementary School levels</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>Custom Group Classes</div>
                <div className={styles.meta}>Flexible Duration, Weekly or Biweekly Meetings</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Flexible scheduling for medium-sized groups</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Customized curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Available on weekends and weekdays</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Tailored for grades 3 through 9</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.meta}>1-on-1 Tutoring</div>
                <div className={styles.meta}>Customized scheduling and duration</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Flexible scheduling</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Individualized curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Online or offline lessons</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Includes mentorship and coaching</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)